/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';

export const DivButtonIcon = styled.div<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => size || '100%'};
  height: ${({ size }) => size || '100%'};
  border-radius: 50%;
  cursor: pointer;
  position: relative;

  &:hover {
    #cta-search {
      visibility: visible;
      /* transition: visibility 0s ease-out 0s; */
    }
  }
`;

export const ctaSearch = css`
  visibility: hidden;
  position: absolute;
  background-color: rgb(26 26 26 / 91%);
  width: min-content;
  top: 6vh;
  min-width: 26vw;
  z-index: 100;
  padding: 1.48vh 1.3vw;
  border-radius: 15px;
  /* transition: visibility 0.2s ease-out 0s; */
  p {
    color: white;
    font-family: var(--font-family-sans);
    font-size: var(--size-18);
    line-height: 1.3;
  }
`;

import styled, { css, keyframes } from 'styled-components';
import { DivButtonIcon } from '../ButtonIcon/style';

const pathFill = css`
  ${DivButtonIcon}:hover & {
    fill: var(--color-black-02);
  }
`;

const pathStroke = css`
${DivButtonIcon}:hover & {
  stroke: var(--color-black-02);
}
`;

const Svg = styled.svg<any>`
  width: ${({ width }) => width || '6.111vh'};
  height: ${({ width }) => width || '6.111vh'};

  ${({ hasTransition, selected }) => hasTransition && css`
    width: ${(!selected ? '1px' : '6.111vh')};
    height: ${(!selected ? '1px' : '6.111vh')};
    transition-duration: 0.2s;
  `};
`;

const circleFill = css<any>`
   ${DivButtonIcon}:hover & {
    fill: ${({ color }) => color || 'var(--color-greenish-cyan)'};
  }
`;

const circleStroke = css<any>`
   ${DivButtonIcon}:hover & {
    stroke: var(--color-greenish-cyan);
  }
`;

const anim = keyframes`
  0%, 50%, 100% {
    transform: scale(1, 1);
  }

  25% {
    transform: scale(-1, 1);
  }
  75% {
    transform: scale(1, -1);
  }
`;

const bounce = css`
  width: 100%;
  height: 100%;
  animation: ${anim} 3s infinite;
  `;

const SvgTrash = styled.svg`
`;
const PathTrash = styled.path`
  ${SvgTrash}:hover & {
    stroke: red;
  }
`;

export default {
  Svg,
  circleFill,
  PathTrash,
  SvgTrash,
  circleStroke,
  pathFill,
  pathStroke,
  bounce,
};
